import React, { ComponentType } from 'react'
import style from './style/TPAPreloaderOverlay.scss'
import { NAMESPACE, TPA_LOADING_KEY, TPA_LOADING_DEFAULT } from './translations'

const TPAPreloaderOverlay: ComponentType<any> = ({ translate, isEditor, appDefinitionName, height }: any) => {
	const styleAttr = { lineHeight: `${height}px` }
	const loading = translate ? translate(NAMESPACE, TPA_LOADING_KEY, TPA_LOADING_DEFAULT) : TPA_LOADING_DEFAULT
	return (
		<div className={isEditor ? style.editorContent : style.content}>
			<div className={isEditor ? style.editorPreloader : style.circlePreloader} />
			{isEditor && (
				<span style={styleAttr}>
					{loading} {appDefinitionName || ''} ...
				</span>
			)}
		</div>
	)
}

export default TPAPreloaderOverlay
